import './App.scss';

function App() {
	return (
		<div className='App'>
			<header className='App-header'>
				<h1> Coding Oasis is under construction</h1>
				<p> Check back later to see the app.</p>
			</header>
		</div>
	);
}

export default App;
